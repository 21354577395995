<template>
    <div class="mainbox_question">
        <div class="fallible">

            <div class="fallible-container">
                <div class="bc">
                    <div class="ttil">
                        <div @click="goback()" class="goback">
                            <i class="el-icon-arrow-left"></i> 返回
                        </div> 易错题
                    </div>
                </div>
                <div class="fallible-top">
                    <div class="fallible-clear" @click="showClearAll">
                        <img src="@/assets/img/questionNew/28.png" />
                        <span>清除所有消灭记录</span>
                    </div>
                    <div class="progress">
                        <span class="sur-number">{{ ycItem.residueNum }}</span>
                        <span class="sur-title">剩余总题数</span>
                        <canvas id="number" width="130" height="130" />
                    </div>
                    <div class="total">
                        <span>累计消灭：</span>
                        <span style="color: #4A6AF0; font-weight: bold">{{ ycItem.wipeNum }}</span>
                        <span style="color: #4A6AF0">道</span>
                    </div>
                </div>
                <div class="table mt24">
                    <div class="theader">
                        <span>题型</span>
                        <span>错题数量</span>
                        <span>操作</span>
                    </div>
                    <div class="tbody">
                        <div class="tcontent">
                            <div class="t-item" v-for="(item, index) in list" :key="index">
                                <template v-if="item.questionType == 0">
                                    <img src="@/assets/img/questionNew/15.png" class="t-item-icon" />
                                    <span class="t-item-name">单选题</span>
                                </template>
                                <template v-if="item.questionType == 1">
                                    <img src="@/assets/img/questionNew/21.png" class="t-item-icon" />
                                    <span class="t-item-name">多选题</span>
                                </template>
                                <template v-if="item.questionType == 2">
                                    <img src="@/assets/img/questionNew/22.png" class="t-item-icon" />
                                    <span class="t-item-name">判断题</span>
                                </template>
                                <template v-if="item.questionType == 6">
                                    <img src="@/assets/img/questionNew/16.png" class="t-item-icon" />
                                    <span class="t-item-name t-item-name-i">不定项题</span>
                                </template>
                                <template v-if="item.questionType == 3">
                                    <img src="@/assets/img/questionNew/18.png" class="t-item-icon" />
                                    <span class="t-item-name">填空题</span>
                                </template>
                                <template v-if="item.questionType == 4">
                                    <img src="@/assets/img/questionNew/17.png" class="t-item-icon" />
                                    <span class="t-item-name">简答题</span>
                                </template>
                                <template v-if="item.questionType == 5">
                                    <img src="@/assets/img/questionNew/20.png" class="t-item-icon" />
                                    <span class="t-item-name">组合题</span>
                                </template>
                                <div class="t-item-number">
                                    <div class="t-item-num-i">
                                        <span>总共</span>
                                        <span>{{ item.dataNum }}</span>
                                        <span>道</span>
                                    </div>
                                    <div class="t-item-num-i">
                                        <span>已消灭</span>
                                        <span>{{ item.wipeNum }}</span>
                                        <span>道</span>
                                    </div>
                                    <div class="t-item-num-i">
                                        <span>剩余</span>
                                        <span>{{ item.residueNum }}</span>
                                        <span>道</span>
                                    </div>
                                </div>
                                <div class="t-item-but" v-if="item.residueNum != 0" @click="start(item, 1)">消灭错题
                                </div>
                                <div class="t-item-but t-item-but-2" v-else @click="againStart(item)">重新消灭</div>
                            </div>
                            <div class="noData" v-if="list.length == 0">暂无数据</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mask-fixed" v-if="clearShow">
            <div class="clear">
                <div class="clear-title">恭喜您消灭了所有【{{ getQuestionTypeName() }}】错题！</div>
                <div class="clear-but">
                    <div class="clear-but-i clear-but-1" @click="showClearAgain">重新消灭</div>
                    <div class="clear-but-i clear-but-2" @click="showClear">确定</div>
                </div>
            </div>
        </div>

        <!-- 清空所有记录 -->
        <div class="mask-fixed" v-if="clearAllShow">
            <div class="clear">
                <div class="clear-title">确定要清空所有记录吗？</div>
                <div class="clear-but">
                    <div class="clear-but-i clear-but-1" @click="showClearAll">取消</div>
                    <div class="clear-but-i clear-but-2" @click="showClearAllSubmit">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Vue from 'vue'
import { getInfo } from '@/api/cookies'
import screen from '@/components/questionNew/screen.vue'

import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
    props: {
        bxId: {
            default: '',
            type: Number | String
        },
        bxType: {
            default: '',
            type: Number | String
        },
    },
    components: { screen },
    data() {
        return {
            courseNameList: [], // 试卷列表
            info: {},
            getInfo: null,
            courseNameInfo: '',
            courseNameId: '',
            clearAllShow: false,
            clearShow: false,
            screenShow: false,
            ycItem: {},
            list: [],
            isShowClearTips: sessionStorage.getItem("fallibleTips")
                ? JSON.parse(sessionStorage.getItem("fallibleTips"))
                : ''

        }
    },
    async created() {
        this.userInfo = getInfo()
        if (!this.userInfo) {
            Vue.prototype.goLoginView(false)
            return
        }
        this.courseNameId = this.$route.query.courseNameId

        await this.getFallibilityData()
        await this.getFallibilityTypeSubject()

    },
    methods: {

        async getFallibilityData() {
            answerClient.getFallibilityData({ kcId: this.courseNameId, userId: this.userInfo.id }).then(async res => {
                this.ycItem = res.data
                this.setCanvas();
            })
        },
        async getFallibilityTypeSubject() {
            answerClient.getFallibilityTypeSubject({ kcId: this.courseNameId, userId: this.userInfo.id }).then(async res => {
                this.list = res.data

                if (sessionStorage.getItem("ifDio") == 1) {
                    const item = this.list.find((i) => i.questionType == sessionStorage.getItem("questionType"));
                    if (item.residueNum == 0) {
                        this.showClear();
                    }

                }
            })
        },
        // showCanvasFn() {
        //     this.showCanvas = true;
        //     setTimeout(() => {
        //         this.setCanvas();
        //     }, 100);
        // },
        setCanvas() {
            const percentage = (this.ycItem.wipeNum / this.ycItem.fallibilityNum) * 100;

            const canvas = document.getElementById("number");
            const ctx = canvas.getContext("2d");

            const canvasW = 130;
            const canvasH = 130;

            const r = 55;
            const lineWidth = 10;
            ctx.lineCap = "round";
            // 外环
            ctx.strokeStyle = "#B9DEFF";
            ctx.lineWidth = lineWidth;
            ctx.beginPath();
            ctx.arc(canvasW / 2, canvasH / 2, r, 0, Math.PI * 1.5);
            ctx.stroke();

            // 外环填充
            const gradient = ctx.createLinearGradient(0, 0, 90, 0);
            gradient.addColorStop("0", "#4A6AF0");
            gradient.addColorStop("1", "#6FA0FF");
            ctx.strokeStyle = gradient;
            ctx.lineWidth = lineWidth;
            ctx.beginPath();
            ctx.arc(canvasW / 2, canvasH / 2, r, 0, Math.PI * 1.5 * (percentage / 100));
            ctx.stroke();

            // 白点
            ctx.strokeStyle = "#fff";
            ctx.lineWidth = 5;
            ctx.beginPath();
            ctx.arc(canvasW / 2, canvasH / 2, r, Math.PI * 1.5 * (percentage / 100), Math.PI * 1.5 * (percentage / 100));
            ctx.stroke();
        },
        // 清空所有记录

        showClearAll() {
            this.clearAllShow = !this.clearAllShow;
        },
        showClearAllSubmit() {
            answerClient
                .deleteFallibilityList({
                    kcId: this.courseNameId, userId: this.userInfo.id
                })
                .then(() => {
                    this.getFallibilityData()
                    this.getFallibilityTypeSubject()
                });
            this.showClearAll();
        },
        showClear() {
            this.clearShow = !this.clearShow;
            sessionStorage.removeItem("ifDio");
        },
        async showClearAgain() {
            sessionStorage.removeItem("ifDio");
            this.start(this.isShowClearTips, 2);
            this.showClear();
        },
        againStart(item) {
            this.clearAllShow = !this.clearAllShow;
            sessionStorage.setItem("fallibleTips", JSON.stringify(item))
            this.start(item, 2)
        },
        // 消灭错题
        start(item, index) {
            sessionStorage.setItem("fallibleTips", JSON.stringify(item))
            this.$router.push({
                path: "/questionNew/questionAnswerOther",
                query: {
                    courseNameId: this.courseNameId,
                    questionType: item.questionType,
                    makerType: 3,
                    type: 2,
                    xmtype: index,
                    questionTypeName: item.questionTypeName
                },
            });
        },
        getQuestionTypeName() {
            switch (Number(sessionStorage.getItem("questionType"))) {
                case 0:
                    return "单选题";
                case 1:
                    return "多选题";
                case 2:
                    return "判断题";
                case 3:
                    return "填空题";
                case 4:
                    return "简答题";
                case 5:
                    return "组合题";
                case 6:
                    return "不定项题";
            }
        },
        goback() {
            this.$router.go(-1)
        }

    }
}
</script>
<style lang="less" scoped>
.fallible {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    background: #fff;
    padding-bottom: 30px;
    background-image: url("~@/assets/img/questionNew/52.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    padding: 20px;

    .fallible-container {
        width: 100%;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
    }

    .fallible-top {
        width: 100%;
        height: 206px;
        background-image: url("~@/assets/img/questionNew/53.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .fallible-clear {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #777;
            cursor: pointer;
            position: absolute;
            top: 32px;
            right: 34px;

            img {
                width: 15px;
                height: 15px;
                margin-right: 8px;
            }
        }

        .progress {
            width: 146px;
            height: 126px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-image: url("~@/assets/img/questionNew/54.png");
            background-size: 100% auto;
            background-repeat: no-repeat;

            canvas {
                transform: translateY(10px) rotate(135deg);
            }

            .sur-number {
                font-size: 30px;
                color: #333;
                font-weight: bold;
                position: absolute;
                top: 40px;
                left: 50%;
                transform: translateX(-50%);
            }

            .sur-title {
                font-size: 13px;
                color: #333;
                position: absolute;
                top: 85px;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .total {
            font-size: 16px;
            color: #333;
            text-align: center;
        }
    }
}

.clear {
    width: 383px;
    border-radius: 12px;
    background-color: #fff;
    padding: 28px 0;

    .clear-title {
        font-size: 20px;
        color: #333;
        text-align: center;
        margin-bottom: 32px;
    }

    .clear-but {
        display: flex;
        align-items: center;
        justify-content: center;

        .clear-but-i {
            width: 142px;
            height: 42px;
            border-radius: 21px;
            font-size: 16px;
            text-align: center;
            line-height: 42px;
            margin: 0 12px;
            font-weight: 600;
            border: none;
            cursor: pointer;
        }

        .clear-but-1 {
            background-color: #F8FAFF;
            color: #4A6AF0;
        }

        .clear-but-2 {
            background-color: #4A6AF0;
            color: #fff;
        }
    }
}

.theader {
    // box-shadow: 0px 3px 6px 1px #F0F4FF;
}

.table {
    box-shadow: 0px 0px 12px 1px rgba(204, 204, 204, 0.3);
    border-radius: 12px;

    .theader {
        height: 58px;
        background-color: #F0F4FF;
        display: flex;
        align-items: center;
        padding-left: 34px;
        font-size: 16px;
        color: #333;
        font-weight: 600;
        border-radius: 12px 12px 0 0;
        position: relative;

        span:nth-child(1) {
            margin-right: 164px;
        }

        span:nth-child(2) {
            margin-right: 374px !important;
        }
    }

    .tbody {
        border-radius: 0 0 12px 12px;
        background-color: #fff;
        padding: 16px 14px 14px;
    }

    .tcontent {
        background-color: #F8FAFF;
        border-radius: 8px;
        padding: 0 20px;

        .t-item {
            padding: 16px 0;
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;

            &:last-child {
                border-bottom: none;
            }

            .t-item-icon {
                width: 46px;
                height: 46px;
                margin-right: 16px;
            }

            .t-item-name {
                font-size: 16px;
                color: #333;
                margin-right: 70px;
                display: block;
                min-width: 65px;
            }

            .t-item-number {
                flex: 1;

                .t-item-num-i {
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 15px;
                    color: #333;

                    span {
                        &:nth-child(1) {
                            margin-right: 5px;
                        }

                        &:nth-child(2) {
                            color: #4A6AF0;
                        }
                    }
                }
            }

            .t-item-but {
                width: 100px;
                height: 36px;
                border-radius: 18px;
                background-color: #4A6AF0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 15px;
                color: #fff;
                cursor: pointer;
            }

            .t-item-but-2 {
                border: 1px solid #4A6AF0;
                color: #4A6AF0;
                background-color: transparent;
            }
        }
    }
}

.noData {
    text-align: center;
    padding: 20px 0;
    color: #909399;
}

.bc {
    background: #fff;

    border-radius: 8px;

    .ttil {
        position: relative;
        text-align: center;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .goback {
        position: absolute;


        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #777777;
        top: 2px;
        cursor: pointer;
    }
}
</style>
